import React from "react"
import { useIntl, FormattedMessage, FormattedHTMLMessage, Link } from "gatsby-plugin-intl"
import Seo from "../../../components/SEO"
import Header from "../../../components/Header"
import PageNav from "../../../components/PageNav"

const Laurits = (props) => {

    const intl = useIntl()

    return (
        <div>
            <Seo
                title={intl.formatMessage({ id: "texts.laurits.title.seo" })}
            />
            <Header />

            <div className="sir-breadcrumbs">
                <Link class="text-sir-black-50" to="/"><FormattedMessage id="global.sir" /></Link>
                <span class="text-sir-black-25 mx-1">/</span>
                <Link class="text-sir-black-50" to="/great-love"><FormattedMessage id="global.menu.greatlove" /></Link>
                <span class="text-sir-black-25 mx-1">/</span>
                <Link class="text-sir-black-50" to="/great-love/texts"><FormattedMessage id="global.menu.texts" /></Link>
                <span class="text-sir-black-25 mx-1">/</span>
                <FormattedMessage id="texts.laurits.author.surname" />
            </div>

            <div className="sir-section sir-layout-text">
                <p className="body-s uppercase text-center mb-4"><FormattedMessage id="texts.laurits.author" /></p>
                <h1 className="title uppercase text-center mb-4"><FormattedMessage id="texts.laurits.title" /></h1>
                <h2 className="title text-center mb-16"><FormattedMessage id="texts.laurits.subtitle" /></h2>

                <p><FormattedHTMLMessage id="texts.laurits.text.p1" /></p>
                <p><FormattedHTMLMessage id="texts.laurits.text.p2" /></p>
                <p><FormattedHTMLMessage id="texts.laurits.text.p3" /></p>
                <p><FormattedHTMLMessage id="texts.laurits.text.p4" /></p>
                <p><FormattedHTMLMessage id="texts.laurits.text.p5" /></p>
                <p><FormattedHTMLMessage id="texts.laurits.text.p6" /></p>
                <p><FormattedHTMLMessage id="texts.laurits.text.p7" /></p>
                <p><FormattedHTMLMessage id="texts.laurits.text.p8" /></p>
                <p><FormattedHTMLMessage id="texts.laurits.text.p9" /></p>
                <p><FormattedHTMLMessage id="texts.laurits.text.p10" /></p>
                <p><FormattedHTMLMessage id="texts.laurits.text.p12" /></p>
                <p><FormattedHTMLMessage id="texts.laurits.text.p13" /></p>
                <p><FormattedHTMLMessage id="texts.laurits.text.p14" /></p>
                <p><FormattedHTMLMessage id="texts.laurits.text.p15" /></p>
                <div className="border-b border-black w-1/3 mb-4"></div>
                <ol className="body-s list-decimal list-inside">
                    <li><FormattedHTMLMessage id="texts.laurits.text.footnote1" /></li>
                </ol>
            </div>

            <PageNav />
        </div>
    )
}

export default Laurits;
